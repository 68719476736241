import { get, post } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { z } from "zod";

import { environmentConfig } from "../../environment";
import {
  type PlacementCandidate,
  placementCandidateSchema,
  placementCandidateWorkerAttributeIncludedSchema,
} from "../types";

interface UsePlacementCandidateParams {
  workerId: string;
}

const createPlacementCandidateRequestSchema = z.object({
  data: z.object({
    type: z.literal("placement-candidates"),
    attributes: z.object({ workerId: z.string() }),
  }),
});

const placementCandidateResponseSchema = z.object({
  data: z.array(placementCandidateSchema),
  included: z.array(placementCandidateWorkerAttributeIncludedSchema),
});

const createPlacementCandidateResponseSchema = z.object({
  data: placementCandidateSchema,
  included: z.array(placementCandidateWorkerAttributeIncludedSchema),
});

function transformResponseIntoPlacementCandidate(
  response:
    | z.infer<typeof placementCandidateResponseSchema>
    | z.infer<typeof createPlacementCandidateResponseSchema>
) {
  const placementCandidate = Array.isArray(response.data) ? response.data[0] : response.data;
  const worker = response.included.find(
    (item) => item.type === "workers" && item.id === placementCandidate.relationships.worker.data.id
  );
  if (!worker) {
    throw new Error("Candidate details not found");
  }

  return {
    ...placementCandidate.attributes,
    id: placementCandidate.id,
    worker: { ...worker.attributes, id: worker.id },
  };
}

const placementCandidateApiUrl = `${environmentConfig.REACT_APP_BASE_API_URL}/placement-candidates`;

export function getPlacementCandidateQueryKey(workerId: string) {
  return [placementCandidateApiUrl, workerId];
}

export function useCreateOrGetPlacementCandidate(
  params: UsePlacementCandidateParams,
  options?: UseQueryOptions<PlacementCandidate>
) {
  const { workerId } = params;
  return useQuery({
    queryKey: getPlacementCandidateQueryKey(workerId),
    queryFn: async () => {
      const response = await get({
        url: placementCandidateApiUrl,
        queryParams: {
          page: {
            size: 1,
          },
          filter: {
            "worker.id": workerId,
          },
        },
        responseSchema: placementCandidateResponseSchema,
      });
      if (Array.isArray(response.data.data) && response.data.data.length > 0) {
        return transformResponseIntoPlacementCandidate(response.data);
      }

      const createCandidateResponse = await post({
        url: placementCandidateApiUrl,
        data: {
          data: {
            type: "placement-candidates",
            attributes: {
              workerId,
            },
          },
        },
        requestSchema: createPlacementCandidateRequestSchema,
        responseSchema: createPlacementCandidateResponseSchema,
      });

      return transformResponseIntoPlacementCandidate(createCandidateResponse.data);
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_OR_GET_PLACEMENT_CANDIDATE_FAILURE,
    },
    ...options,
  });
}
